
import { Courses } from "@/api";
import { Course } from "@/api/types";
import PageTitle from "@/components/common/PageTitle.vue";
import InstructorsList from "@/components/instructors/InstructorsList.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {
    InstructorsList,
    PageTitle,
    ValidationProvider,
    ValidationObserver
  }
})
export default class EditCoursePage extends Vue {
  tableHeader = {
    title: "Assign Instructor",
    icon: "mdi-account"
  };

  headers = [
    { text: "Instructor", value: "instructor", align: "left" },
    { text: "Email", value: "email", align: "center" },
    { text: "Phone number", value: "phoneNumber", align: "center" },
    { text: "Dates available", value: "datesAvailable", align: "center" },
    { text: "Hours", value: "hours", align: "center" }
  ];
  buttonsArray = [
    {
      text: "Back",
      icon: "mdi-chevron-left",
      color: "white",
      action: "back"
    },
    {
      text: "Delete",
      icon: "mdi-delete",
      color: "gray",
      action: "delete"
    },
    {
      text: "Save",
      icon: "mdi-pencil-box-outline",
      color: "dark",
      action: "save",
      disabled: true
    }
  ];
  assessmentsArray: any[] = [];
  headersTitle = [{ icon: "mdi-calendar-month", header: "Edit Course" }];
  selectedInstructors: Array<number> = [];
  oldSelectedInstructors: Array<number> = [];

  course: Course = {
    courseType: "string",
    courseTypeId: 0,
    duration: undefined,
    id: 0,
    instructors: [],
    instructorName: "",
    name: "Course name",
    price: 0,
    status: true,
    credits: 0,
    category: { id: 0, title: "" },
    assessments: [],
    dol: "",
    saqaUnitStandard: "",
    skillsProgramId: ""
  };
  oldCourse: Course = {} as Course;
  durations: any = [];
  categories = [];
  types = [];

  @Watch("course.category.id", { deep: true })
  async onCategoryChange(val: number) {
    this.course.dol = val === 3 || val == 9 ? this.course.dol : null;
    if (val == 8) {
      this.durations.push({
        id: 365,
        name: "365-days",
        day: 365
      });
    } else {
      if (this.durations.length > 5) this.durations.pop();
    }
  }

  @Watch("course", { deep: true })
  onChange() {
    // eslint-disable-next-line no-undef
    Object.keys(this.course).forEach(key => {
      if (
        this.oldCourse[key as keyof Course] != this.course[key as keyof Course]
      ) {
        const index = this.buttonsArray.findIndex(b => b.action === "save");
        this.buttonsArray[index].disabled = false;
      }
    });
  }

  @Watch("selectedInstructors.length")
  handlerSelectedInstructors(newValue: number) {
    if (this.oldSelectedInstructors.length !== newValue) {
      const index = this.buttonsArray.findIndex(b => b.action === "save");
      this.buttonsArray[index].disabled = false;
    }
  }

  get courseId() {
    return this.$route.params.id;
  }

  get isSkillProgramme() {
    return this.course?.category?.id === 9;
  }
  get isOccupationalCertificate() {
    return this.course?.category?.id === 8;
  }

  get outcomeLabel() {
    if (this.isOccupationalCertificate) return "Expert Level Outcome";
    if (this.isSkillProgramme) return "Exit Skills Outcome";
    return "Specific Outcome";
  }

  async mounted() {
    try {
      [
        this.course,
        this.types,
        this.durations,
        this.categories
      ] = await Promise.all([
        Courses.info(this.courseId),
        Courses.types(),
        Courses.durations(),
        Courses.categories()
      ]);

      this.assessmentsArray = this.course.assessments;
      for (let i = 0; i < 12; i++) {
        if (!this.assessmentsArray[i]) {
          this.assessmentsArray.push({ title: "" });
        }
      }
      if (this.course.instructors !== undefined) {
        this.selectedInstructors = this.course.instructors.map(
          (course: { id: number; name: string }) => course.id
        );
      }
      this.oldSelectedInstructors = [...this.selectedInstructors];
      this.oldCourse = { ...this.course };
      this.durations = this.durations.slice(0, 5);
    } catch (e) {
      const err = e as any;
      await this.$error(err.errors);
    }
  }

  async save() {
    try {
      this.course.assessments = this.assessmentsArray.filter(
        assessment => assessment?.title !== ""
      );
      const data = JSON.parse(JSON.stringify(this.course));
      data.instructors = this.selectedInstructors;
      data.categoryId = data.category.id;
      delete data.category;
      if (data.categoryId === 3) {
        delete data.duration;
      } else {
        data.courseDurationId = data.duration.id;
        delete data.duration;
      }

      if (this.isSkillProgramme) {
        delete data.saqaUnitStandard;
      } else {
        delete data.skillsProgramId;
      }

      await Courses.update(this.courseId, data);
      this.course.assessments = [];
      await this.$success("<strong>Success!</strong> Course has been updated!");

      if (this.$route.name === "EditCourse") {
        await this.$router.push({ name: "CoursesList" });
      }
    } catch (e) {
      const err = e as any;
      this.course.assessments = [];
      (this.$refs.courseForm as Vue & {
        setErrors: (errors: any) => void;
      }).setErrors(err.errors);
      await this.$error(err.errors);
    }
  }

  async onSave() {
    (this.$refs.form as Vue & {
      click: () => void;
    }).click();
  }

  async onGoBack() {
    await this.$router.push({ name: "CoursesList" });
  }

  async onDelete() {
    const res = await this.$dialog.error({
      text: "Do you really want to delete item?",
      title: "Warning",
      persistent: false,
      actions: {
        No: { text: "No" },
        Yes: {
          color: "red",
          text: "Yes I do"
        }
      }
    });
    if (res === "Yes") {
      try {
        await Courses.remove(this.courseId);

        if (this.$route.name === "EditCourse") {
          await this.$router.push({ name: "CoursesList" });
        }
      } catch (e) {
        const err = e as any;
        if (err.result.status === 400) {
          this.$error({ error: `Can't be deleted, ${err.result.message}` });
        } else await this.$error(err.errors);
      }
    }
  }
}
